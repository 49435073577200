import './ImageUpload.css';
import Logo from '../Logo';
import MaterialIcon from '../MaterialIcon';
import Dropzone, {useDropzone, FileWithPath} from 'react-dropzone';
import { ImageType } from '../../types';

type Props = {
    setImage: (image: ImageType) => void;
}

export default function ImageUpload(props: Props){
    const { getRootProps } = useDropzone({onDrop});    

    function onDrop(acceptedFiles: FileWithPath[]){
        const file = acceptedFiles[0];
        props.setImage({
            name: file.name,
            src: URL.createObjectURL(file)
        })
    }

    return (
        <div id="image-upload" {...getRootProps({className: 'dropzone', onClick: event => event.stopPropagation()})}>
            <Logo/>
            <Dropzone onDrop={acceptedFiles => onDrop(acceptedFiles)}>
                {({getRootProps, getInputProps}) => (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <MaterialIcon id="upload"/>
                        <h3>Upload an image</h3>
                        <span>Drag and drop, or click to select</span>
                    </div>
                )}
            </Dropzone>
        </div>
    )
}