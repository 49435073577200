import { XYCoord } from "../../types";
import { CONTEXT_MENU_WIDTH } from "../../constants";

type Props = {
    mouseLoc: XYCoord,
    closeMenu: () => void,
    deleteBox: () => void
}

export default function BoxContextMenu(props: Props){
    const style = { left: props.mouseLoc.x, top: props.mouseLoc.y, width: CONTEXT_MENU_WIDTH }

    function handleDeleteBox(){
        props.deleteBox();
        props.closeMenu();
    }
    return (
        <div id="box-context-menu" style={style}>
            <div className="context-menu-item" aria-label="Box delete button" onClick={handleDeleteBox}>
                <span>Delete box</span>
            </div>
        </div>
    )
}
