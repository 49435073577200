import { DraggableData, ResizableDelta, Rnd } from "react-rnd";
import React from "react";
import { BOX_COLORS, MIN_BOX_SIZE } from "../../constants";
import { DragBoxType, XYCoord } from "../../types";
import { OptionsContext } from "../../context/OptionsContext";

type Props = {
    box: DragBoxType;
    updateBox: (boxId: number, newBoxData: Partial<DragBoxType>) => void;
    openContextMenu: (boxId: number, mouseLoc: XYCoord) => void;
}

export default function DragBox(props: Props){
    const [rescaleFactor, setRescaleFactor] = React.useState(1);

    const parentWidthPreScale = React.useRef(props.box.parent.initWidth);

    const options = React.useContext(OptionsContext)

    // Add an event listener that fires off a function that updates our rescaleFactor when the window is resized
    React.useEffect(() => {
        function generateRescaleFactor(){
            setRescaleFactor(props.box.parent.ref.clientWidth / parentWidthPreScale.current);
        }
        window.addEventListener("resize", generateRescaleFactor);
        return () => window.removeEventListener("resize", generateRescaleFactor);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Whenever rescale factor changes, lets update our box with the new scaled values
    React.useEffect(() => {
        parentWidthPreScale.current = (props.box.parent.ref.clientWidth);
        props.updateBox(props.box.id, {
            x: props.box.x * rescaleFactor,
            y: props.box.y * rescaleFactor,
            width: props.box.width * rescaleFactor,
            height: props.box.height * rescaleFactor,
            parent: {
                ...props.box.parent,
                initWidth: parentWidthPreScale.current
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rescaleFactor])

    // Holds our initial mouse position when resizing a box
    const resizeStartLoc = React.useRef({ x: 0, y: 0 });

    function handleResizeStart(e: React.MouseEvent<HTMLElement, MouseEvent>){
        // Get our cursor start position, so we can determine direction of a resize change
        resizeStartLoc.current = { x: e.clientX, y: e.clientY };
    }

    function handleResizeStop(e: MouseEvent, delta: ResizableDelta){
        if(delta.width !== 0 || delta.height !== 0){
            // Parent object offsets
            const parentXOffset = props.box.parent.ref!.offsetLeft;
            const parentYOffset = props.box.parent.ref!.offsetTop;

            // Distance traveled from initial mouseDown to mouseUp
            // movement left and top are positive values, right and bottom are negative
            const xOffset = resizeStartLoc.current.x - e.clientX;
            const yOffset = resizeStartLoc.current.y - e.clientY;

            // Amount of pixels from an edge in which a user can still trigger a resize event
            const draggablePx = 12;
            
            let newX = props.box.x;
            let newY = props.box.y;
            let newWidth = props.box.width + delta.width;
            let newHeight = props.box.height + delta.height;

            // If we're dragging the left border to the left
            if (resizeStartLoc.current.x - parentXOffset < props.box.x + draggablePx) newX = props.box.x - xOffset;

            // If we're dragging the top border to the top
            if (resizeStartLoc.current.y - parentYOffset < props.box.y + draggablePx) newY = props.box.y - yOffset;

            // If user made box smaller than our minimum, set it to minimum values
            if (newWidth < MIN_BOX_SIZE) newWidth = MIN_BOX_SIZE;
            if (newHeight < MIN_BOX_SIZE) newHeight = MIN_BOX_SIZE;

            // If user dragged past the smallest box size, don't move the box
            const maxResizeX = props.box.x + props.box.width - MIN_BOX_SIZE;
            const maxResizeY = props.box.y + props.box.height - MIN_BOX_SIZE;
            if(newX > maxResizeX) newX = maxResizeX;
            if(newY > maxResizeY) newY = maxResizeY;

            props.updateBox(props.box.id, { 
                x: newX,
                y: newY,
                width: props.box.width + delta.width,
                height: props.box.height + delta.height
            });
        }
    }

    function handleDragStop(data: DraggableData){
        props.updateBox(props.box.id, { x: data.x, y: data.y })
    }

    // Get our box border/background color from our list of predefined colors
    const boxColor = BOX_COLORS[ props.box.id % BOX_COLORS.length ];

    const style = {
        opacity: `${options.viewOptions.showBoxes ? 1 : 0}`,
        border: `2px solid rgb(${ boxColor })`,
        backgroundColor: `rgba(${ boxColor }, .1)`,
        boxShadow: "0px 0px 1px 0px rgba(0,0,0,0.5)",
        // Change our shape between rectangle and ellipse so users can preview what it would cover
        borderRadius: `${options.redactionOptions.shape === "ellipse" ? "50%" : "0px" }`
    }

    function handleContextMenu(e: React.MouseEvent){
        e.preventDefault();
        props.openContextMenu(props.box.id, {x: e.clientX, y: e.clientY});
    }

    return (
        <Rnd
            bounds={"parent"}
            onMouseDown={e => e.stopPropagation()}
            position={{ x: props.box.x, y: props.box.y }}
            size={{ width: props.box.width, height: props.box.height }}
            minWidth={MIN_BOX_SIZE}
            minHeight={MIN_BOX_SIZE}
            style={style}
            onDragStop={(_e, data) => handleDragStop(data)}
            onResizeStart={e => handleResizeStart(e as React.MouseEvent<HTMLElement, MouseEvent>)}
            onResizeStop={(e, _dir, _ref, delta) => handleResizeStop(e as MouseEvent, delta)}
            onContextMenu={(e: React.MouseEvent) => handleContextMenu(e)}
            resizeHandleStyles={{
                topLeft: {height: 8, width: 8, top: -5, left: -5},
                topRight: {height: 8, width: 8, top: -5, right: -5},
                bottomRight: {height: 8, width: 8, bottom: -5, right: -5},
                bottomLeft: {height: 8, width: 8, bottom: -5, left: -5},
                left: {width: 6, left: -4},
                top: {height: 6, top: -4},
                bottom: {height: 6, bottom: -4},
                right: {width: 6, right: -4}
            }}
        />
    )
}
