import './ImageEditor.css';

import React from "react";
import DragBox from './DragBox';
import { calculateBoxLayout } from "../helpers";
import { DragBoxType, XYCoord, NewBoxType, ImageType } from '../../types';
import { OptionsContext } from '../../context/OptionsContext';
import Menu from './Menu';
import BoxContextMenu from './BoxContextMenu';
import { CONTEXT_MENU_WIDTH, MIN_BOX_SIZE } from '../../constants';

import * as StackBlur from 'stackblur-canvas';

type Props = {
    image: ImageType;
    closeImage: () => void;
}

// How many px change is required to trigger a rerender
// Used to limit how often we re-render while a user is drawing their box
export const THRESHHOLD_TO_UPDATE = 2;

export default function ImageEditor(props: Props){
    const [dragBoxes, setDragBoxes] = React.useState<JSX.Element[]>([]);
    const [boxes, setBoxes] = React.useState<DragBoxType[]>([]);
    const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false);
    const [newBox, setNewBox] = React.useState<NewBoxType>();
    const [newBoxStyle, setNewBoxStyle] = React.useState<React.CSSProperties>({ display: "none" });
    const [dragBoxContainerStyle, setDragBoxContainerStyle] = React.useState<React.CSSProperties>({});

    // Context menu state
    const [contextMenuLocation, setContextMenuLocation] = React.useState({ x: 0, y: 0 });
    const [selectedBoxId, setSelectedBoxId] = React.useState(0);
    const [showBoxContextMenu, setShowBoxContextMenu] = React.useState(false);

    const imageRef = React.useRef(null);
    const canvasRef = React.useRef(null);
    const bgCanvasRef = React.useRef(null);

    const options = React.useContext(OptionsContext);

    // Reset some settings that might confuse a user when they load a new image in
    React.useEffect(() => {
        options.setViewOptions({showBoxes: true});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // "Draw our box" as a user is making a selection
    React.useEffect(() => {
        if(newBox){
            const {y, x, width, height} = calculateBoxLayout(newBox);
            setNewBoxStyle({ 
                display: "block", top: y, left: x, width, height, 
                borderRadius: `${options.redactionOptions.shape === "ellipse" ? "50%" : "0px" }`
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBox])

    React.useEffect(() => {
        function rescaleDragBoxContainer(){
            if(imageRef.current && (imageRef.current as HTMLImageElement).width !== dragBoxContainerStyle.width){
                setDragBoxContainerStyle({
                    height: (imageRef.current as HTMLImageElement).height,
                    width: (imageRef.current as HTMLImageElement).width
                })
            }
        }
        window.addEventListener("resize", rescaleDragBoxContainer);
        return () => window.removeEventListener("resize", rescaleDragBoxContainer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Mouse event handlers for a user creating a new box
    function handleMouseDown(e: React.MouseEvent){
        if(isMouseDown){
            createDragBox();
            return;
        }
        setIsMouseDown(true);
        setNewBox({
            offset: { top: (e.target as HTMLElement).offsetTop, left: (e.target as HTMLElement).offsetLeft},
            start: { x: e.pageX, y: e.pageY },
            end: { x: e.pageX, y: e.pageY }
        });
    }
    function handleMouseMove(e: React.MouseEvent){
        if(isMouseDown && newBox){
            const oldMinusNewX = newBox.end.x - e.pageX;
            const oldMinusNewY = newBox.end.y - e.pageY;
            const newMinusOldX = e.pageX - newBox.end.x;
            const newMinusOldY = e.pageY - newBox.end.y;
            // Make sure our change is large enough to trigger a re-render
            if((oldMinusNewX > THRESHHOLD_TO_UPDATE) || (oldMinusNewY > THRESHHOLD_TO_UPDATE) ||
            (newMinusOldX > THRESHHOLD_TO_UPDATE) || (newMinusOldY > THRESHHOLD_TO_UPDATE)){
                setNewBox({ ...newBox, end: { x: e.pageX, y: e.pageY } });
            }
        };
    }

    // Fired on a mouseUp, or mouseDown after an off-image drag
    function createDragBox(){
        if(newBox && imageRef.current){
            setIsMouseDown(false);
            setNewBoxStyle({ display: "none" });
            // Create our drag box using newBox current state
            const {y, x, width, height} = calculateBoxLayout(newBox);
            // Selection has to be larger than our min size to create a box
            const newBoxId = boxes.length ? boxes[boxes.length - 1].id + 1 : 1;

            // Validate this was a drag event and not a click
            if(width >= 1 || height >= 1){
                setBoxes([
                    ...boxes,
                    { 
                        id: newBoxId,
                        y, x,
                        width: width > MIN_BOX_SIZE ? width : MIN_BOX_SIZE,
                        height: height > MIN_BOX_SIZE ? height : MIN_BOX_SIZE,
                        parent: {
                            ref: imageRef.current,
                            initWidth: (imageRef.current as HTMLImageElement).clientWidth
                        }
                    }
                ]);
            }
            setNewBox(undefined);
        }
    }

    function renderImage(){
        if(canvasRef.current && bgCanvasRef.current && imageRef.current){
            const canvas = canvasRef.current as HTMLCanvasElement;
            const bgCanvas = bgCanvasRef.current as HTMLCanvasElement;
            const image = imageRef.current as HTMLImageElement;

            // Set up our canvases
            canvas.width = image.naturalWidth;
            canvas.height = image.naturalHeight;
            bgCanvas.width = image.naturalWidth;
            bgCanvas.height = image.naturalHeight;
            const ctx = canvas.getContext("2d");
            const bgCtx = bgCanvas.getContext("2d");

            // Bringing in SizeFactor makes our redaction strength similar between different size images
            const sizeFactor = (image.naturalHeight + image.naturalHeight) / 1000;

            // Draw our background canvas and apply our redaction method
            switch (options.redactionOptions.method) {
                case "mosaic":
                    break;
                case "pixel":
                    const pixelStrength = Math.floor((options.redactionOptions.strength / 5) * sizeFactor);
                    const scaledWidth = image.naturalWidth / pixelStrength;
                    const scaledHeight = image.naturalHeight / pixelStrength;

                    bgCtx!.imageSmoothingEnabled = false;
                    bgCtx!.drawImage(image, 0, 0, scaledWidth, scaledHeight);
                    bgCtx!.drawImage(bgCanvas, 0, 0, scaledWidth, scaledHeight, 0, 0, image.naturalWidth, image.naturalHeight);
                    break;
                case "blur":
                    let blurStrength = Math.floor(options.redactionOptions.strength * ((sizeFactor / 5) * 1.8));

                    // Maximum StackBlur supported strength is 180
                    if(blurStrength > 180) blurStrength = 180;

                    bgCtx!.drawImage(image, 0, 0);
                    StackBlur.canvasRGBA(bgCanvas,
                        0,
                        0,
                        image.naturalWidth,
                        image.naturalHeight,
                        blurStrength
                    )
                    break;
                case "fill":
                    bgCtx!.fillStyle = options.redactionOptions.color;
                    bgCtx!.fillRect(0, 0, canvas.width, canvas.height);
                    break;
            }
            // Draw our real canvas
            ctx!.drawImage(image, 0 , 0);

            // Draw and cut out our paths for each box
            for (let box of boxes){
                const rescaleFactor = image.naturalWidth / box.parent.initWidth;
                const rescaledBox = {
                    x: Math.floor(box.x * rescaleFactor),
                    y: Math.floor(box.y * rescaleFactor),
                    width: Math.ceil(box.width * rescaleFactor),
                    height: Math.ceil(box.height * rescaleFactor),
                }
                ctx!.globalCompositeOperation='destination-out';
                ctx!.beginPath();
                switch (options.redactionOptions.shape) {
                    case "rectangle":
                        ctx!.rect(rescaledBox.x, rescaledBox.y, rescaledBox.width, rescaledBox.height);
                        break;
                    case "ellipse":
                        const xRadius = rescaledBox.width / 2;
                        const yRadius = rescaledBox.height / 2;
                        ctx!.ellipse(
                            rescaledBox.x + xRadius,
                            rescaledBox.y + yRadius,
                            xRadius,
                            yRadius,
                            0, 0, 180
                        );
                        break;
                }
                ctx!.fill();
            }

            // Fill our background canvas into the holes where the boxes were
            ctx!.globalCompositeOperation='destination-over';
            ctx!.drawImage(bgCanvas, 0 , 0);
            ctx!.globalCompositeOperation='source-over';

            // Download the image
            const fileName = props.image.name.slice(0, props.image.name.lastIndexOf('.'));

            const fileExtension = props.image.name.slice(props.image.name.lastIndexOf('.'));
            let dataType = "image/jpeg";
            if (fileExtension.includes("png")) dataType = "image/png";

            const anchor = document.createElement("a");
            anchor.href = canvas.toDataURL(dataType);
            anchor.download = `REDACTED_${fileName}`;
            anchor.click();

            // Cleanup
            anchor.remove();
        }
    }

    function generateDragBoxes(){
        const newDragBoxes: JSX.Element[] = [];
        boxes.forEach((box) => {
            newDragBoxes.push(
                <DragBox
                    key={`dragBox_${box.id}`}
                    box={box}
                    updateBox={updateBox}
                    openContextMenu={openBoxContextMenu}
                />
            );
        });
        setDragBoxes(newDragBoxes);
    }

    // Regenerate our DragBoxes whenever we add/remove one to the array
    React.useEffect(() => {
        generateDragBoxes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [boxes])

    // Function we pass to our DragBoxes/ContextMenu to let them update our box state
    function updateBox(boxId: number, newBoxData?: Partial<DragBoxType>){
        let indexToUpdate;
        for(let box of boxes){
            if (box.id === boxId) indexToUpdate = boxes.indexOf(box);
        }
        if(indexToUpdate !== undefined){
            const newBoxes = boxes;
            // If we have newData, update, otherwise delete the provided boxId
            if (newBoxData) newBoxes[indexToUpdate] = { ...boxes[indexToUpdate], ...newBoxData };
            else newBoxes.splice(indexToUpdate, 1);
            
            setBoxes(newBoxes);
            // Manually trigger our dragBox generation since useEffect won't know it changed
            generateDragBoxes();
        }
    }

    function openBoxContextMenu(boxId: number, mouseLoc: XYCoord){
        if(imageRef.current){
            const image = (imageRef.current as HTMLImageElement);
            const pageWidth = image.offsetLeft + image.clientWidth;
            // Make sure our context menu wont run off the page
            if(pageWidth < mouseLoc.x + CONTEXT_MENU_WIDTH) setContextMenuLocation({ ...mouseLoc, x: pageWidth - CONTEXT_MENU_WIDTH });
            else setContextMenuLocation(mouseLoc);

            setSelectedBoxId(boxId);
            setShowBoxContextMenu(true);
        }
    }
    
    return (
        <div style={{display: "flex", width: "100%"}}>
            <Menu closeImage={props.closeImage} renderImage={renderImage}/>
            <div id="image-editor" onClick={() => setShowBoxContextMenu(false)} onContextMenu={(e) => e.preventDefault()}>
                {showBoxContextMenu &&
                    <BoxContextMenu
                        mouseLoc={contextMenuLocation}
                        closeMenu={() => setShowBoxContextMenu(false)}
                        deleteBox={() => updateBox(selectedBoxId)}
                    />
                }
                <div
                    id="dragbox-container"
                    style={dragBoxContainerStyle}
                    onMouseDown={e => handleMouseDown(e)}
                    onMouseMove={e => handleMouseMove(e)}
                    onMouseUp={createDragBox}
                >
                    <div id="newbox" style={newBoxStyle}/>
                    {dragBoxes}
                </div>
                <img
                    alt="Preview"
                    src={props.image.src}
                    ref={imageRef}
                    onLoad={e => {
                        setDragBoxContainerStyle({
                            height: (e.target as HTMLImageElement).height,
                            width: (e.target as HTMLImageElement).width
                        })
                    }}
                />
                <canvas style={{display: "none"}} id="image-canvas" ref={canvasRef}/>
                <canvas style={{display: "none"}} id="bg-image-canvas" ref={bgCanvasRef}/>
            </div>
        </div>
    )
}
