import React from 'react';
import './App.css';
import ImageEditor from './components/ImageEditor';
import ImageUpload from './components/ImageUpload/ImageUpload';
import { REDACTION_METHODS, REDACTION_SHAPES } from './constants';
import { OptionsContext } from './context/OptionsContext';
import { ImageType, RedactionOptions, ViewOptions } from './types';

function App() {
    const [image, setImage] = React.useState<ImageType>({ name: "", src: "" });
    const [viewOptions, setViewOptions] = React.useState<ViewOptions>({ showBoxes: true })
    const [redactionOptions, setRedactionOptions] = React.useState<RedactionOptions>({
        method: REDACTION_METHODS[0].value,
        shape: REDACTION_SHAPES[0].value,
        color: "#000",
        strength: 50
    });

    if(image.src){
        return (
            <OptionsContext.Provider value={{
                redactionOptions: redactionOptions,
                viewOptions: viewOptions,
                setRedactionOptions: setRedactionOptions,
                setViewOptions: setViewOptions
            }}>
                <div id="image-redactor-app">
                    <ImageEditor image={image} closeImage={() => setImage({ name: "", src: "" })}/>
                </div>
            </OptionsContext.Provider>
        );
    }
    return (
        <div id="image-redactor-app">
            <ImageUpload setImage={setImage}/>
        </div>
    )
}

export default App;
