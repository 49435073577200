import { NewBoxType } from "../types";

export function calculateBoxLayout(box: NewBoxType): {y: number, x: number, width: number, height: number}{
    const {offset, start, end} = box;
    const isStartXGreater = start.x > end.x;
    const isStartYGreater = start.y > end.y;
    return({
        y: isStartYGreater ? end.y - offset.top : start.y - offset.top,
        x: isStartXGreater ? end.x - offset.left : start.x - offset.left,
        width: isStartXGreater ? start.x - end.x : end.x - start.x,
        height: isStartYGreater ? start.y - end.y : end.y - start.y
    })
}
