import './Menu.css';

import React from 'react';

import Logo from '../../Logo';
import MaterialIcon from '../../MaterialIcon';
import { REDACTION_METHODS, REDACTION_SHAPES, RENDER_REPEAT_DELAY } from '../../../constants';
import { OptionsContext } from '../../../context/OptionsContext';

type Props = {
    closeImage: () => void;
    renderImage: () => void;
}

export default function Menu(props: Props){
    const options = React.useContext(OptionsContext);
    const [renderLocked, setRenderLocked] = React.useState(false);

    function handleRenderImage(){
        if (!renderLocked) {
            props.renderImage();
            setRenderLocked(true);
            setTimeout(() => {
                setRenderLocked(false);
            }, RENDER_REPEAT_DELAY);
        }
    }
    return (
        <div id="image-redactor-menu">
            <Logo/>
            <div className="option-group">
                <h2>Viewing Settings</h2>
                <div className="option-box">
                    <div>
                        <label>Show boxes</label>
                        <button
                            className="toggle-button"
                            onClick={() => options.setViewOptions({...options.viewOptions, showBoxes: !options.viewOptions.showBoxes})}>
                            <MaterialIcon id={options.viewOptions.showBoxes ? 'visibility' : 'visibility_off'}/> 
                        </button>
                    </div>
                </div>
            </div>
            <div className="option-group">
                <h2>Redaction Options</h2>
                <div className="option-box">
                    {REDACTION_SHAPES.length > 1 &&
                        <label>Shape
                            <select 
                                value={options.redactionOptions.shape}
                                onChange={(e) => options.setRedactionOptions({...options.redactionOptions, shape: e.target.value})}
                            >
                                {REDACTION_SHAPES.map((shape) => {
                                    return <option key={`shape_${shape.value}`} value={shape.value}>{shape.display}</option>
                                })}
                            </select>
                        </label>
                    }
                    {REDACTION_METHODS.length > 1 &&
                        <label>Method
                            <select 
                                value={options.redactionOptions.method}
                                onChange={(e) => options.setRedactionOptions({...options.redactionOptions, method: e.target.value})}
                            >
                                {REDACTION_METHODS.map((method) => {
                                    return <option key={`method_${method.value}`} value={method.value}>{method.display}</option>
                                })}
                            </select>
                        </label>
                    }
                    {options.redactionOptions.method === "fill" && 
                        <label>Fill Color
                            <input type="color" value={options.redactionOptions.color}
                                onChange={(e) => options.setRedactionOptions({...options.redactionOptions, color: e.target.value})}
                            />
                        </label>
                    }
                    {options.redactionOptions.method !== "fill" && 
                        <label>Redaction Strength
                            <input type="range" min="20" max="100" step="1" value={options.redactionOptions.strength}
                                onChange={(e) => options.setRedactionOptions({...options.redactionOptions, strength: parseInt(e.target.value)})}
                            />
                        </label>
                    }
                </div>
            </div>
            <div id="menu-button-container">
                <button className={`btn-full-width btn-primary ${renderLocked && "btn-disabled"}`} onClick={handleRenderImage}>
                    {renderLocked ? <span className="material-icons loadingSpinner">workspaces</span> : "Render & Export"}
                </button>
                <button className="btn-text" onClick={props.closeImage}>Close image</button>
            </div>
        </div>
    )
}