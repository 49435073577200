// Supported redaction shapes, first item will be used as default value
export const REDACTION_SHAPES = [
    { display: "Rectangle", value: "rectangle" },
    { display: "Ellipse", value: "ellipse" }
]

// Supported redaction methods, first item will be used as default value
export const REDACTION_METHODS = [
    // { display: "Mosaic", value: "mosaic" },
    { display: "Pixelate", value: "pixel" },
    { display: "Blur", value: "blur" },
    { display: "Fill", value: "fill" }
]

// Array of colors to loop through when creating DragBoxes
export const BOX_COLORS = [
    "249, 104, 149",
    "247, 104, 249",
    "196, 104, 249",
    "104, 155, 249",
    "104, 249, 240",
    "104, 249, 169",
    "121, 249, 104",
    "249, 244, 104",
    "249, 179, 104"
]

// How wide our DragBox context menus should be
export const CONTEXT_MENU_WIDTH = 120;

// Minimum size to create a new box, setting this too small can create issues when editing a box
export const MIN_BOX_SIZE = 16;

// Minimum time (ms) between render requests, to prevent a user from multi-click downloading accidentally
export const RENDER_REPEAT_DELAY = 500;
