import React from "react";
import { RedactionOptions, ViewOptions } from "../types";

type OptionsContextType = {
    redactionOptions: RedactionOptions,
    viewOptions: ViewOptions,
    setRedactionOptions: React.Dispatch<React.SetStateAction<RedactionOptions>>,
    setViewOptions: React.Dispatch<React.SetStateAction<ViewOptions>>
}

export const OptionsContext = React.createContext<OptionsContextType>({
    redactionOptions: {
        method: "",
        shape: "",
        color: "",
        strength: 50
    },
    viewOptions: {
        showBoxes: true
    },
    setRedactionOptions: () => {},
    setViewOptions: () => {}
});